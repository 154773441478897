import React, { Component } from 'react';
import PropTypes from 'prop-types';

import core from 'modules/core';

import '../styles.scss';

const { Input, FormNavigation, Checkbox } = core.components;

class ProfileDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name,
            agencyName: props.agencyName,
            email: props.email,
            phone: props.phone,
            password: props.password,
            realEstate: props.realEstate,
            mortgage: props.mortgage,
            software: props.software,
            solar: props.solar,
            insurance: props.insurance,
            travel: props.travel,
            automotive: props.automotive,
            financialServices: props.financialServices,
            homeServices: props.homeServices,
            support: props.support,
            other: props.other,
            loading: false,
            chatbotName: 'Aisa'
        };

        this.validateName = this.validateName.bind(this);
        this.validateAgencyName = this.validateAgencyName.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateDomains = this.validateDomains.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeAgencyName = this.changeAgencyName.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.toggleRealEstate = this.toggleRealEstate.bind(this);
        this.toggleMortgage = this.toggleMortgage.bind(this);
        this.toggleSoftware = this.toggleSoftware.bind(this);
        this.toggleSolar = this.toggleSolar.bind(this);
        this.toggleInsurance = this.toggleInsurance.bind(this);
        this.toggleTravel = this.toggleTravel.bind(this);
        this.toggleAutomotive = this.toggleAutomotive.bind(this);
        this.toggleFinancialServices = this.toggleFinancialServices.bind(this);
        this.toggleHomeServices = this.toggleHomeServices.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
        this.toggleOther = this.toggleOther.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        name: PropTypes.string,
        agencyName: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.string,
        phone: PropTypes.string,
        realEstate: PropTypes.bool,
        mortgage: PropTypes.bool,
        software: PropTypes.bool,
        solar: PropTypes.bool,
        insurance: PropTypes.bool,
        travel: PropTypes.bool,
        automotive: PropTypes.bool,
        financialServices: PropTypes.bool,
        homeServices: PropTypes.bool,
        support: PropTypes.bool,
        other: PropTypes.bool,
        history: PropTypes.object.isRequired,
        submitDetails: PropTypes.func.isRequired,
        submitAddons: PropTypes.func.isRequired,
        cleanSignup: PropTypes.func.isRequired,
        signupForStructurely: PropTypes.func.isRequired,
        setBillingClientReferenceId: PropTypes.func.isRequired,
        addons: PropTypes.array
    };

    static defaultProps = {
        name: '',
        agencyName: '',
        email: '',
        password: '',
        phone: '',
        realEstate: false,
        mortgage: false,
        software: false,
        solar: false,
        insurance: false,
        travel: false,
        automotive: false,
        homeServices: false,
        financialServices: false,
        support: false,
        other: false,
        addons: []
    };

    validateName(name) {
        if (!name) {
            return 'The name field is required';
        } else if (name.trim().split(' ').length < 2) {
            return 'Both a first and last name are required';
        }
    }

    validateAgencyName(name) {
        if (!name) {
            return 'The company name field is required';
        }
    }

    validateEmail(email) {
        if (!email) {
            return 'The email field is required';
        } else {
            let re = /\S+@\S+\.\S+/;

            if (!re.test(email)) {
                return 'The email is invalid, please use a valid email address.';
            }
        }
    }

    validatePhone(phone) {
        if (!phone) {
            return 'The phone field is required';
        } else if (phone.replace(/\D/g, '').length !== 10) {
            return 'The phone field must contain a 10 digit code representing a phone number';
        }
    }

    validatePassword(password) {
        const minPasswordLength = 8;

        if (!password) {
            return 'The password field is required';
        } else if (password.length < minPasswordLength) {
            return `Your password must be at least ${minPasswordLength} characters long`;
        }
    }

    validateDomains(domains) {
        if (!domains) return 'Must select at least one domain';
    }

    changeName(e) {
        this.setState({ name: e.currentTarget.value });
    }

    changeAgencyName(e) {
        this.setState({ agencyName: e.currentTarget.value });
    }

    changeEmail(e) {
        this.setState({ email: e.currentTarget.value });
    }

    changePhone(e) {
        const { phone } = this.state;
        const { helpers } = core;

        let value = e.currentTarget.value;
        let phoneNumber = value.replace(/\D/g, '');

        if (value) {
            if (helpers.getFormattedPhoneNumber(phone).length > value.length) {
                phoneNumber = phone.substring(0, phone.length - 1);
            }

            if (phoneNumber.length > 10) {
                phoneNumber = phoneNumber.substring(0, 10);
            }

            this.setState({ phone: phoneNumber });
        }
    }

    changePassword(e) {
        this.setState({ password: e.currentTarget.value });
    }

    toggleRealEstate() {
        this.setState(prevState => ({ realEstate: !prevState.realEstate }));
    }

    toggleMortgage() {
        this.setState(prevState => ({ mortgage: !prevState.mortgage }));
    }

    toggleSoftware() {
        this.setState(prevState => ({ software: !prevState.software }));
    }

    toggleSolar() {
        this.setState(prevState => ({ solar: !prevState.solar }));
    }

    toggleInsurance() {
        this.setState(prevState => ({ insurance: !prevState.insurance }));
    }

    toggleTravel() {
        this.setState(prevState => ({ travel: !prevState.travel }));
    }

    toggleAutomotive() {
        this.setState(prevState => ({ automotive: !prevState.automotive }));
    }

    toggleFinancialServices() {
        this.setState(prevState => ({
            financialServices: !prevState.financialServices
        }));
    }

    toggleHomeServices() {
        this.setState(prevState => ({ homeServices: !prevState.homeServices }));
    }

    toggleSupport() {
        this.setState(prevState => ({ support: !prevState.support }));
    }

    toggleOther() {
        this.setState(prevState => ({ other: !prevState.other }));
    }

    toggleAddon(addon) {
        const { addons, submitAddons } = this.props;

        let newAddons = addons.slice(0);

        if (newAddons.includes(addon)) {
            newAddons = newAddons.filter(
                existingAddon => existingAddon !== addon
            );
        } else {
            newAddons.push(addon);
        }

        submitAddons(newAddons);
    }

    validateForm() {
        const {
            name,
            agencyName,
            email,
            phone,
            password,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            homeServices,
            financialServices,
            support,
            other
        } = this.state;

        let hasErrors =
            this.validateName(name) ||
            this.validateEmail(email) ||
            this.validatePhone(phone) ||
            this.validatePassword(password) ||
            this.validateDomains(
                realEstate ||
                    mortgage ||
                    software ||
                    solar ||
                    insurance ||
                    travel ||
                    automotive ||
                    financialServices ||
                    homeServices ||
                    support ||
                    other
            );

        hasErrors = hasErrors || this.validateAgencyName(agencyName);

        return hasErrors;
    }

    onSubmit() {
        const { showPopover, capitalize } = core.helpers;
        const {
            history,
            submitDetails,
            signupForStructurely,
            setBillingClientReferenceId
        } = this.props;
        const {
            name,
            agencyName,
            email,
            phone,
            password,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            homeServices,
            financialServices,
            support,
            other
        } = this.state;

        let hasErrors = this.validateForm();
        if (hasErrors) {
            showPopover(hasErrors, 'error');
            return hasErrors;
        }

        this.setState({ loading: true });

        let fullName = name.split(' ');
        fullName = `${capitalize(fullName[0])} ${capitalize(fullName[1])}`;

        submitDetails(
            fullName,
            email,
            phone,
            password,
            agencyName,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            homeServices,
            financialServices,
            support,
            other
        );

        signupForStructurely({
            name: fullName,
            email,
            phone,
            password,
            agencyName,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            homeServices,
            financialServices,
            support,
            other
        })
            .then(result => {
                const agentId = result?.data?.signupForStructurely?.agentId;
                if (agentId) {
                    setBillingClientReferenceId(agentId);
                    history.push('/subscribe/billing');
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                if (error && error.graphQLErrors) {
                    error.graphQLErrors.forEach(error => {
                        showPopover(error.message, 'error');
                    });
                } else {
                    showPopover('Failed to check email', 'error');
                }
            });

        return hasErrors;
    }

    renderAgencyName() {
        const { agencyName } = this.state;

        return (
            <Input
                id="agency-input"
                label="Company Name"
                value={agencyName}
                autocomplete="organization"
                onChange={this.changeAgencyName}
                onEnter={this.onSubmit}
                validator={this.validateAgencyName}
            />
        );
    }

    renderAddons() {
        const { realEstate } = this.state;

        if (!realEstate) {
            return <div></div>;
        }

        return <div></div>;
    }

    render() {
        const {
            name,
            email,
            phone,
            password,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            homeServices,
            financialServices,
            support,
            other
        } = this.state;
        const { getFormattedPhoneNumber } = core.helpers;

        let nextText = 'Submit';

        return (
            <section className="details-section">
                <div className="form-wrapper">
                    <div className="details-form form">
                        <Input
                            id="name-input"
                            label="Full Name"
                            value={name}
                            autocomplete="name"
                            onChange={this.changeName}
                            onEnter={this.onSubmit}
                            validator={this.validateName}
                            autoFocus={true}
                        />
                        {this.renderAgencyName()}
                        <Input
                            id="phone-input"
                            label="Phone Number"
                            type="phone"
                            autocomplete="tel"
                            value={getFormattedPhoneNumber(phone)}
                            onChange={this.changePhone}
                            onEnter={this.onSubmit}
                            validator={this.validatePhone}
                        />
                        <Input
                            id="email-input"
                            label="Work Email"
                            type="email"
                            value={email}
                            autocomplete="email"
                            onChange={this.changeEmail}
                            onEnter={this.onSubmit}
                            validator={this.validateEmail}
                        />
                        <Input
                            id="password-input"
                            label="Password"
                            type="password"
                            value={password}
                            autocomplete="new-password"
                            onChange={this.changePassword}
                            onEnter={this.onSubmit}
                            validator={this.validatePassword}
                        />
                        <div className="checkbox-group">
                            <h4 className="checkbox-group-title">Industry</h4>
                            <div className="domains_group">
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Real Estate"
                                        checked={realEstate}
                                        onChange={this.toggleRealEstate}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Mortgage"
                                        checked={mortgage}
                                        onChange={this.toggleMortgage}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Software"
                                        checked={software}
                                        onChange={this.toggleSoftware}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Solar"
                                        checked={solar}
                                        onChange={this.toggleSolar}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Insurance"
                                        checked={insurance}
                                        onChange={this.toggleInsurance}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Travel"
                                        checked={travel}
                                        onChange={this.toggleTravel}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Automotive"
                                        checked={automotive}
                                        onChange={this.toggleAutomotive}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Financial Services"
                                        checked={financialServices}
                                        onChange={this.toggleFinancialServices}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Home Services"
                                        checked={homeServices}
                                        onChange={this.toggleHomeServices}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Support"
                                        checked={support}
                                        onChange={this.toggleSupport}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Other"
                                        checked={other}
                                        onChange={this.toggleOther}
                                    />
                                </div>
                            </div>
                            <div ClassName="container">
                                <div>
                                    By entering your information and submitting
                                    this form, you agree to our{' '}
                                    <a href="https://www.structurely.com/terms">
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://www.structurely.com/privacy">
                                        {' '}
                                        Privacy Policy
                                    </a>{' '}
                                    that you may be contacted by Structurely by
                                    phone, text message, and email. Frequency of
                                    contact may vary and message and data rates
                                    may apply. Text STOP to cancel.
                                </div>
                            </div>
                        </div>
                        {this.renderAddons()}
                    </div>
                    <FormNavigation
                        onSubmit={() => this.onSubmit()}
                        btnText={nextText}
                    />
                </div>
            </section>
        );
    }
}

export default ProfileDetails;
